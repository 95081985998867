import { default as errorTD3gxBm2RgMeta } from "/vercel/path0/pages/error.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as no_45active_45pricelistII5SAgfgJuMeta } from "/vercel/path0/pages/no-active-pricelist.vue?macro=true";
import { default as partnerJAVlLdrtbgMeta } from "/vercel/path0/pages/partner.vue?macro=true";
import { default as paymentqSZWcJbf8VMeta } from "/vercel/path0/pages/payment.vue?macro=true";
import { default as registrationjA8JV179ddMeta } from "/vercel/path0/pages/registration.vue?macro=true";
import { default as sentry_45test_45pagexajFo9jxcwMeta } from "/vercel/path0/pages/sentry-test-page.vue?macro=true";
import { default as successW4sLGie1V5Meta } from "/vercel/path0/pages/success.vue?macro=true";
import { default as time_45up0CraEs9f41Meta } from "/vercel/path0/pages/time-up.vue?macro=true";
export default [
  {
    name: "error",
    path: "/error",
    meta: errorTD3gxBm2RgMeta || {},
    component: () => import("/vercel/path0/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/vercel/path0/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "no-active-pricelist",
    path: "/no-active-pricelist",
    meta: no_45active_45pricelistII5SAgfgJuMeta || {},
    component: () => import("/vercel/path0/pages/no-active-pricelist.vue").then(m => m.default || m)
  },
  {
    name: "partner",
    path: "/partner",
    meta: partnerJAVlLdrtbgMeta || {},
    component: () => import("/vercel/path0/pages/partner.vue").then(m => m.default || m)
  },
  {
    name: "payment",
    path: "/payment",
    meta: paymentqSZWcJbf8VMeta || {},
    component: () => import("/vercel/path0/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationjA8JV179ddMeta || {},
    component: () => import("/vercel/path0/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "sentry-test-page",
    path: "/sentry-test-page",
    component: () => import("/vercel/path0/pages/sentry-test-page.vue").then(m => m.default || m)
  },
  {
    name: "success",
    path: "/success",
    meta: successW4sLGie1V5Meta || {},
    component: () => import("/vercel/path0/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "time-up",
    path: "/time-up",
    meta: time_45up0CraEs9f41Meta || {},
    component: () => import("/vercel/path0/pages/time-up.vue").then(m => m.default || m)
  }
]