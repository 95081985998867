import type { NavigationFailure, RouteLocationNamedRaw, RouteLocationPathRaw } from 'vue-router'
import { useSelectionStore } from '~/store/selection-store'
import { useUserStore } from '~/store/user-store'
import { usePriceListStore } from '~/store/price-list-store'
import type { Vehicle } from '~/types/Vehicle'
import type { RouteLocationNormalized } from '#vue-router'
// Not in use because we currently don't set the subscription plan based on the query parameters
// import type { SubscriptionPlan } from '~/types/SubscriptionPlan'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<void | NavigationFailure | false | string | RouteLocationPathRaw | RouteLocationNamedRaw> => {
  if (import.meta.client) {
    const selectionStore = useSelectionStore()
    const userStore = useUserStore()
    const priceListStore = usePriceListStore()

    const { subscription, payment, isLoggedIn } = storeToRefs(userStore)
    const {
      selectedStore,
      selectedVehicle
      // Not in use because we currently don't set the subscription plan based on the query parameters
      // selectedSubscriptionPlan
    } = storeToRefs(selectionStore)
    const { enabledVehicles } = storeToRefs(priceListStore)

    // If user is coming back here from the payment page, cancel the subscription and reset the subscription and payment
    if (from.path === '/payment') {
      await userStore.cancelSubscription()
      userStore.resetSubscriptionAndPayment()
    }

    // Redirect to home if no storeId is provided from either query parameters or store
    const storeId = selectedStore.value?.id ?? to.query.storeId as string ?? null
    if (!storeId) {
      return navigateTo('/')
    }

    // Fetch the active price list for the store
    const priceListResponse = await priceListStore.getActivePriceList(storeId)

    // Redirect to error page if no active price list is found
    if (priceListResponse?.error?.statusCode === 404) {
      return navigateTo('/no-active-pricelist')
    }
    // Set the selected vehicle plan based on the query parameters
    selectedVehicle.value = enabledVehicles.value.find((vehicle: Vehicle) => vehicle.id === to.query.vehicleId) ?? null
    // Set the selected subscription plan based on the query parameters (currently not in use)
    // selectedSubscriptionPlan.value = selectedVehicle.value?.subscription_plans.find((subscriptionPlan: SubscriptionPlan) => subscriptionPlan.id === to.query.subscriptionPlanId) ?? null

    // Continue only if user is logged in
    if (!isLoggedIn.value) {
      return
    }

    // Get subscriptions for the user
    await userStore.getSubscriptions()

    // Create payment when status is pending_activation
    if (subscription.value?.status === 'pending_activation') {
      await userStore.createPayment()

      // Get stripe publishable key when status is pending_activation and redirect to payment page
      await userStore.getStripePublishableKey()
      return navigateTo('/payment')
    }

    // Redirect to success page if subscription status is already active
    if (subscription.value?.status === 'active') {
      return navigateTo('/success')
    }
  }
})
