import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/pages/runtime/validate.js";
import middleware_45global from "/vercel/path0/middleware/middleware.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  middleware_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "index-middleware": () => import("/vercel/path0/middleware/indexMiddleware.ts"),
  partner: () => import("/vercel/path0/middleware/partner.ts"),
  payment: () => import("/vercel/path0/middleware/payment.ts"),
  registration: () => import("/vercel/path0/middleware/registration.ts")
}