import * as Sentry from '@sentry/vue'
import { name, version } from '../package.json'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import type { Plugin } from '#app'

const sentry: Plugin = defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig()
  const router = useRouter()

  if (config.public.vercelEnv !== 'production') {
    return
  }

  Sentry.init({
    app: vueApp,
    dsn: config.public.sentryDsn,
    debug: config.public.vercelEnv !== 'production',
    sampleRate: 1,
    environment: config.public.vercelEnv,
    release: `${name}@${version}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true
      })

    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  })
})

export default sentry
