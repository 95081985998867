import revive_payload_client_zKdUYaP6lr from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lQ6NVHWZ8x from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_R6vjD3sZRv from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MYKBoUInLO from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6ldXeJfeDB from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_j2fMEafIzF from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_gIhpMMnXYW from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dzsBq7hO0B from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.19.0_typescript@5.5.4_vue@3.4.33_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_aBOqHC05fA from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._hjbnwofiuv2cmbo4qbwgfv42ea/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6Ph1iQzNHk from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_7DXxVSj6ql from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.0_vue@3.4.33_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_kQ7BMwQTPL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.0_vue@3.4.33_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_tqYzmO3pOZ from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.19.0_typesc_fdfitcvigflwmbuvzxx42otgae/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import floating_vue_BiOD74u9sH from "/vercel/path0/plugins/floating-vue.ts";
import router_options_Vfa2sdOsAH from "/vercel/path0/plugins/router.options.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import vue_cookie_comply_with_reject_client_vBi4yoXDDv from "/vercel/path0/plugins/vue-cookie-comply-with-reject.client.ts";
export default [
  revive_payload_client_zKdUYaP6lr,
  unhead_lQ6NVHWZ8x,
  router_R6vjD3sZRv,
  payload_client_MYKBoUInLO,
  navigation_repaint_client_6ldXeJfeDB,
  check_outdated_build_client_j2fMEafIzF,
  chunk_reload_client_gIhpMMnXYW,
  plugin_vue3_dzsBq7hO0B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_aBOqHC05fA,
  plugin_6Ph1iQzNHk,
  switch_locale_path_ssr_7DXxVSj6ql,
  i18n_kQ7BMwQTPL,
  plugin_tqYzmO3pOZ,
  floating_vue_BiOD74u9sH,
  router_options_Vfa2sdOsAH,
  sentry_client_shVUlIjFLk,
  vue_cookie_comply_with_reject_client_vBi4yoXDDv
]