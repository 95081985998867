import { defineStore } from 'pinia'
import type { FetchError } from 'ofetch'
import type { Slot } from '~/types/Slot'
import type { Vehicle } from '~/types/Vehicle'
import type { Product } from '~/types/Product'
import type { Store } from '~/types/Store'
import { usePriceListStore } from '~/store/price-list-store'
import { useApi } from '~/composables/api'
import type { SubscriptionPlan } from '~/types/SubscriptionPlan'
import type { ItemSubscriptionPlan } from '~/types/ItemSubscriptionPlan'
import type { BusinessAccount } from '~/types/BusinessAccount'

export const useSelectionStore = defineStore('userSelection', () => {
  const { fetchWithoutAuth } = useApi()
  const priceListStore = usePriceListStore()
  const { $i18n: i18n } = useNuxtApp()
  const selectedCountryId = ref<string | null>(null)
  const selectedPartnerId = ref<string | null>(null)
  const stores = ref<Array<Store>>([])
  const sortedStores = computed((): Array<Store> => stores.value.sort((a, b) => a.name.localeCompare(b.name)))
  const slots = ref<Array<Slot>>([])
  const selectedPickupDate = ref<Date | null>(null)
  const selectedPickupSlot = ref<Slot | null>(null)
  const selectedSubscriptionPlan = ref<ItemSubscriptionPlan & SubscriptionPlan | undefined | null>(null)
  const selectedSubscriptionPlanTimeUnit = computed((): string | undefined => {
    if (!selectedSubscriptionPlan.value) {
      return
    }
    return i18n.t(`general.${selectedSubscriptionPlan.value?.billing_frequency}`)
  })
  const selectedVehicle = ref<Vehicle | null>(null)
  const selectedVehiclePrice = computed((): number | undefined => selectedVehicle.value?.subscription_plans.find((plan: ItemSubscriptionPlan) => plan.id === selectedSubscriptionPlan.value?.id)?.price)
  const selectedProducts = ref<Array<Product>>([])
  const selectedStore = ref<Store | null>(null)
  const total = computed((): string | undefined => {
    const { activeCoupons } = storeToRefs(priceListStore)
    if (!Object.keys(selectedVehicle).length) {
      return
    }
    const vehiclePrice = selectedVehicle.value?.subscription_plans.find((plan: ItemSubscriptionPlan) => plan.id === selectedSubscriptionPlan.value?.id)?.price ?? 0
    const productsPrice = selectedProducts.value.reduce((acc, cur) => {
      const currentPrice = cur.subscription_plans?.find((plan: ItemSubscriptionPlan) => plan.subscription_plan_id === selectedSubscriptionPlan.value?.subscription_plan_id)?.price ?? 0
      return acc + currentPrice
    }, 0)
    const euroDeductions = activeCoupons.value.reduce((acc, cur) => {
      if (!selectedSubscriptionPlan.value?.billing_frequency) {
        return acc
      }
      const amountEuro = cur.frequency_amount[selectedSubscriptionPlan.value?.billing_frequency]
      if (!amountEuro) {
        return acc
      }
      return acc + amountEuro
    }, 0)

    const percentageDeductions = activeCoupons.value.reduce((acc, cur) => {
      if (!cur.amount_percent) {
        return acc
      }
      return acc - (cur.amount_percent / 100)
    }, 1)
    const totalWithOutDiscount = vehiclePrice + productsPrice
    const totalAmount = (totalWithOutDiscount * percentageDeductions) - euroDeductions
    return totalAmount > 0 ? totalAmount.toFixed(2) : '0'
  })
  const totalWithoutDiscount = computed((): number | undefined => {
    if (!Object.keys(selectedVehicle).length) {
      return
    }
    const vehiclePrice = selectedVehicle.value?.subscription_plans.find((plan: ItemSubscriptionPlan) => plan.id === selectedSubscriptionPlan.value?.id)?.price ?? 0
    const productsPrice = selectedProducts.value.reduce((acc, cur) => {
      const currentPrice = cur.subscription_plans?.find((plan: ItemSubscriptionPlan) => plan.subscription_plan_id === selectedSubscriptionPlan.value?.subscription_plan_id)?.price ?? 0
      return acc + currentPrice
    }, 0)
    return vehiclePrice + productsPrice
  })
  const getProductPrices = (product: Product): ItemSubscriptionPlan | undefined => product.subscription_plans?.find(
    (plan: ItemSubscriptionPlan) => plan.subscription_plan_id === selectedSubscriptionPlan.value?.subscription_plan_id
  )
  const getPickupSlots = async function ({ date }: { date: Date }): Promise<{ error: FetchError | undefined } | undefined> {
    const { data, error } = await fetchWithoutAuth<Array<Slot>>(`/stores/${selectedStore.value?.id}/slots/pickup`, {
      method: 'POST',
      body: {
        date: date.toISOString()
      }
    })
    if (!data || error) {
      return { error }
    }
    slots.value = data
  }

  const getStores = async function ({ subdomain }: { subdomain: string }): Promise<{ error: FetchError | undefined } | undefined> {
    stores.value = []
    const { data, error } = await fetchWithoutAuth<BusinessAccount>(`/businessAccounts/by-subdomain/${subdomain}`)
    if (!data || error) {
      return { error }
    }
    const innerStores = data.stores
    if (!innerStores?.length) {
      return
    }
    stores.value = innerStores.filter((item: Store) => item.status === 'active')
  }

  const removeUnavailableProducts = (): void => {
    const { products } = storeToRefs(priceListStore)
    for (let i = 0; i < selectedProducts.value.length; i++) {
      const itemInPriceList = products.value.find((product: Product) => product.is_enabled && product.id === selectedProducts.value[i].id)
      if (!itemInPriceList) {
        selectedProducts.value.splice(i, 1)
      }
    }
  }

  const reset = (): void => {
    selectedCountryId.value = null
    selectedPartnerId.value = null
    slots.value = []
    stores.value = []
    selectedVehicle.value = null
    selectedStore.value = null
    selectedPickupDate.value = null
    selectedPickupSlot.value = null
    selectedSubscriptionPlan.value = null
    selectedProducts.value = []
  }

  return {
    selectedCountryId,
    selectedPartnerId,
    slots,
    stores,
    sortedStores,
    selectedPickupDate,
    selectedPickupSlot,
    selectedSubscriptionPlan,
    selectedSubscriptionPlanTimeUnit,
    selectedVehicle,
    selectedVehiclePrice,
    selectedProducts,
    selectedStore,
    total,
    totalWithoutDiscount,
    getProductPrices,
    getPickupSlots,
    getStores,
    reset,
    removeUnavailableProducts
  }
}, {
  persist: false
})
