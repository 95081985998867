import type { RouteLocationNormalizedLoaded } from 'vue-router'
import type { NuxtApp, Plugin } from '#app'
import { defineNuxtPlugin } from '#app'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const routerOptions: Plugin = defineNuxtPlugin((nuxtApp: NuxtApp): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  nuxtApp.$router.options.scrollBehavior = async (to: RouteLocationNormalizedLoaded, from: RouteLocationNormalizedLoaded, savedPosition: { left: number, top: number }): Promise<Record<string, number>> => new Promise((resolve: (value: Record<string, number>) => void) => {
    setTimeout(() => {
      resolve({
        left: savedPosition?.left || 0,
        top: savedPosition?.top || 0
      })
    }, 0)
  })
})

export default routerOptions
