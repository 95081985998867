import { defineStore } from 'pinia'
import type { FetchError } from 'ofetch'
import { useSelectionStore } from '~/store/selection-store'
import { useUserStore } from '~/store/user-store'
import { useApi } from '~/composables/api'
import type { PriceList } from '~/types/PriceList'
import type { Vehicle } from '~/types/Vehicle'
import type { Product } from '~/types/Product'
import type { Store } from '~/types/Store'
import type { Coupon } from '~/types/Coupon'
import type { SubscriptionPlan } from '~/types/SubscriptionPlan'
import type { ItemSubscriptionPlan } from '~/types/ItemSubscriptionPlan'

export const usePriceListStore = defineStore('priceList', () => {
  const { $i18n: i18n } = useNuxtApp()
  const { fetchWithoutAuth } = useApi()
  const selectionStore = useSelectionStore()
  const userStore = useUserStore()
  const isPriceListLoading = ref<boolean>(false)
  const activePriceList = ref<PriceList | null | undefined>(null)
  const activeCoupons = ref<Array<Coupon>>([])
  const vehicles = computed((): Array<Vehicle> => {
    let innerVehicles: Array<Vehicle> = []
    if (!activePriceList.value) {
      return []
    }
    if (Array.isArray(activePriceList.value.vehicle_model_groups)) {
      activePriceList.value.vehicle_model_groups.forEach((group: Vehicle) => group.isGroup = true)
      innerVehicles = innerVehicles.concat(activePriceList.value.vehicle_model_groups)
    }
    if (Array.isArray(activePriceList.value.vehicle_models)) {
      activePriceList.value.vehicle_models.forEach((model: Vehicle) => model.isGroup = false)
      innerVehicles = innerVehicles.concat(activePriceList.value.vehicle_models)
    }
    return innerVehicles
  })
  const enabledVehicles = computed((): Array<Vehicle> => {
    const innerEnabledVehicles = vehicles.value.filter((vehicle: Vehicle) => vehicle.is_enabled)
    const outOfStockVehicles = innerEnabledVehicles.filter((vehicle: Vehicle) => !vehicle.has_stock)
    const inStockVehicles = innerEnabledVehicles.filter((vehicle: Vehicle) => vehicle.has_stock)
    const alphabeticalSort = (a: Vehicle, b: Vehicle): number => a.label[i18n.locale.value].localeCompare(b.label[i18n.locale.value])
    const innerVehicles = [...inStockVehicles.sort(alphabeticalSort), ...outOfStockVehicles.sort(alphabeticalSort)]
    innerVehicles.forEach((vehicle: Vehicle) => {
      vehicle.subscription_plans.forEach((plan: ItemSubscriptionPlan) => {
        const generalPlan = subscriptionPlans.value.find((innerGeneralPlan: SubscriptionPlan) => innerGeneralPlan.id === plan.subscription_plan_id)
        Object.assign(plan, generalPlan)
      })
    })
    return innerVehicles
  })
  const products = computed((): Array<Product> => {
    if (!activePriceList.value) {
      return []
    }
    return activePriceList.value.products ?? []
  })
  const enabledProducts = computed((): Array<Product> => products.value.filter((product: Product) => product.is_enabled))
  const store = computed((): Store | undefined => {
    if (!activePriceList.value) {
      return
    }
    return activePriceList.value.store
  })
  const subscriptionPlans = computed((): Array<SubscriptionPlan> => activePriceList.value?.subscription_plans ?? [])
  const getActivePriceList = async function (storeId: string): Promise<{ error: FetchError | undefined } | undefined> {
    const { selectedStore } = storeToRefs(selectionStore)
    const { user } = storeToRefs(userStore)
    isPriceListLoading.value = true
    const { data, error } = await fetchWithoutAuth<PriceList>(`/stores/${storeId}/activePriceList`)
    if (!data || error) {
      isPriceListLoading.value = false
      return { error }
    }
    isPriceListLoading.value = false
    activePriceList.value = data
    selectedStore.value = data.store
    if (typeof data.store.location?.country === 'string' && user.value) {
      user.value.country = data.store.location?.country
    }
  }

  const redeemCoupon = async function ({ couponCode }: { couponCode: string }): Promise<{ error: FetchError | undefined } | undefined> {
    const { selectedStore } = storeToRefs(selectionStore)
    const { data, error } = await fetchWithoutAuth<Coupon>('/coupons/validate', {
      method: 'POST',
      body: {
        store_id: selectedStore.value?.id,
        coupon: couponCode
      }
    })
    if (!data || error) {
      removeCoupon({ couponCode })
      return { error }
    }
    addCoupon(data)
  }

  const addCoupon = (coupon: Coupon): void => {
    const isCouponAlreadyAdded = activeCoupons.value.some((innerCoupon: Coupon) => innerCoupon.code === coupon.code)
    if (!isCouponAlreadyAdded) {
      activeCoupons.value.push(coupon)
    }
  }
  const removeCoupon = ({ couponCode }: { couponCode: string }): void => {
    activeCoupons.value = activeCoupons.value.filter((coupon: Coupon) => coupon.code !== couponCode)
  }

  return {
    isPriceListLoading,
    activePriceList,
    vehicles,
    enabledVehicles,
    products,
    enabledProducts,
    store,
    activeCoupons,
    subscriptionPlans,
    getActivePriceList,
    redeemCoupon,
    removeCoupon
  }
})
