// https://github.com/Akryum/floating-vue/issues/912
// Should in theory go into the nuxt config

import FloatingVue from 'floating-vue'
import type { App } from 'vue'
import type { Plugin } from '#app'
import { defineNuxtPlugin } from '#app'
import 'floating-vue/dist/style.css'

const floatingVue: Plugin = defineNuxtPlugin(({ vueApp }: { vueApp: App<Element> }) => {
  vueApp.use(FloatingVue)
})

export default floatingVue
