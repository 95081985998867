import type {
  $Fetch,
  FetchContext,
  FetchResponse,
  FetchOptions,
  FetchError
} from 'ofetch'
import { ofetch } from 'ofetch'
import * as Sentry from '@sentry/vue'
import { useUserStore } from '~/store/user-store'

export const useApi = (): {
  fetch: <T> (url: string, options?: FetchOptions) => Promise<{
    data?: T
    error?: FetchError
  }>
  fetchStripeKey: (url: string, options?: FetchOptions) => Promise<{
    data?: string
    error?: FetchError
  }>
  fetchWithoutAuth: <T> (url: string, options?: FetchOptions) => Promise<{
    data?: T
    error?: FetchError
    token?: string
  }>
} => {
  const { $i18n: i18n } = useNuxtApp()
  const locale = i18n.locale.value || 'en'
  const config = useRuntimeConfig()
  const userStore = useUserStore()
  const router = useRouter()
  const baseURL = config.public.backendBaseUrl
  const onRequestError = (context: FetchContext & { error: Error }): void => {
    console.log('onRequestError', context)
  }
  const onResponseError = (context: FetchContext & { response: FetchResponse<unknown> }): void => {
    if (context.response.status === 422) {
      return
    }
    if (context.response.status === 503) {
      void router.push('/maintenance')
      return
    }
    if (context.response.status === 401) {
      userStore.resetUser()
      return
    }
    console.log('onResponseError', context)
    Sentry.captureException(context)
  }
  const fetch = async <T> (url: string, options?: FetchOptions): Promise<{
    data?: T
    error?: FetchError
  }> => {
    let response: {
      data: T
    }
    let error: FetchError
    try {
      response = await $fetch(url, options) as Awaited<{
        data: T
      }>
      return {
        data: response.data
      }
    } catch (e) {
      error = e as FetchError
      return { error }
    }
  }
  const fetchStripeKey = async (url: string, options?: FetchOptions): Promise<{
    data?: string
    error?: FetchError
  }> => {
    let response: string
    let error: FetchError
    try {
      response = await $fetch(url, options) as Awaited<string>
      return {
        data: response
      }
    } catch (e) {
      error = e as FetchError
      return { error }
    }
  }
  const fetchWithoutAuth = async <T> (url: string, options?: FetchOptions): Promise<{
    data?: T
    error?: FetchError
    token?: string
  }> => {
    let response: {
      data: T
      token?: string
    }
    let error: FetchError
    try {
      response = await $fetchWithoutAuth(url, options) as Awaited<{
        data: T
        token?: string
      }>
      return {
        data: response.data,
        token: response.token
      }
    } catch (e) {
      error = e as FetchError
      return { error }
    }
  }
  const $fetch: $Fetch = ofetch.create({
    baseURL,
    headers: {
      'Accept-Language': locale,
      'authorization': `Bearer ${userStore.token}`
    },
    onRequestError,
    onResponseError
  })
  const $fetchWithoutAuth: $Fetch = ofetch.create({
    baseURL,
    headers: {
      'Accept-Language': locale
    },
    onRequestError,
    onResponseError
  })
  return {
    fetch,
    fetchStripeKey,
    fetchWithoutAuth
  }
}
